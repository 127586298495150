import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { isoParse } from "d3-time-format";
import useBaseUtils from "~/functions/useBaseUtils";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useToFormatWatchlist from "~/functions/useToFormatWatchlist"; // import debounce from "lodash/debounce"
export default defineComponent({
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate;
    var _useNumberFormatters = useNumberFormatters(),
      formatPercentTwoDecimals = _useNumberFormatters.formatPercentTwoDecimals,
      displayWholeNumberAsPercentTwoDecimals = _useNumberFormatters.displayWholeNumberAsPercentTwoDecimals,
      displayIntOrDash = _useNumberFormatters.displayIntOrDash,
      dynamicCurrencyFormater = _useNumberFormatters.dynamicCurrencyFormater;
    var _useToFormatWatchlist = useToFormatWatchlist(),
      displayRedWhenValNegative = _useToFormatWatchlist.displayRedWhenValNegative;
    var formatCurrency = dynamicCurrencyFormater(1, "USD");
    return {
      dev: dev,
      refCode: refCode,
      I18nFn: I18nFn,
      utcEpochToShortDate: utcEpochToShortDate,
      formatPercentTwoDecimals: formatPercentTwoDecimals,
      displayWholeNumberAsPercentTwoDecimals: displayWholeNumberAsPercentTwoDecimals,
      displayIntOrDash: displayIntOrDash,
      displayRedWhenValNegative: displayRedWhenValNegative,
      formatCurrency: formatCurrency
    };
  },
  data: function data() {
    return {
      activeColumns: [{
        text: "Company Name",
        value: "SecurityOwnedName",
        align: "center"
      }, {
        text: "Ticker",
        value: "RIC",
        align: "center"
      }, {
        text: "Reported Value of Shares Held (MM)",
        value: "SharesHeldValue",
        align: "center"
      }, {
        text: "This Holding as % of Firm's Portfolio",
        value: "PctPortfolio",
        align: "center"
      }, {
        text: "# Shares Held",
        value: "SharesHeld",
        align: "center"
      }, {
        text: "Change in # of Shares Held",
        value: "SharesHeldChange",
        align: "center"
      }, {
        text: "% Change in # of Shares Held",
        value: "SharesHeldChangePct",
        align: "center"
      }, {
        text: "% of Shares Outstanding Held",
        value: "PctOfSharesOutstanding",
        align: "center"
      }, {
        text: "Holdings Date",
        value: "HoldingsDate",
        align: "center"
      }, {
        text: "Filing Type",
        value: "FilingType",
        align: "center"
      } // { text: "Portfolio Rank", value: "PortfolioRank", align: "center" },
      // {
      //   text: "Name",
      //   value: "SecurityOwnedName",
      //   align: "center"
      // },
      // {
      //   text: "Value change of shares held",
      //   value: "SharesHeldValChg",
      //   align: "center"
      // }
      ]
    };
  },
  computed: {
    investorColumns: function investorColumns() {
      var _this = this;
      return this.activeColumns.map(function (c) {
        return _objectSpread(_objectSpread({}, c), {}, {
          text: _this.I18nFn(c.text)
        });
      });
    },
    id: function id() {
      // what sets this? ObjectId?
      return this.$route.query.id;
    },
    unauth: function unauth() {
      return this.$store.state.trkd.investorHoldingsUnauth;
    },
    unauthMessage: function unauthMessage() {
      var unauthObj = this.unauth;
      if (unauthObj) {
        var name = unauthObj.name;
        var numHoldings = unauthObj.num;
        var type = unauthObj.type;
        return "To see detailed information on ".concat(type, " ").concat(name, " ").concat(numHoldings, " Equity Holdings");
      } else {
        return "";
      }
    },
    holdings: function holdings() {
      var _this2 = this;
      var Holdings = Object.hasOwn(this.$store.state.trkd.investorHoldings, "Holdings") ? this.$store.state.trkd.investorHoldings.Holdings : {};
      var rawData = Object.hasOwn(Holdings, "HoldingDetails") ? this.$store.state.trkd.investorHoldings.Holdings.HoldingDetails : [];
      return rawData.map(function (d) {
        try {
          var SecurityOwnedName = d.SecurityOwnedName;
          var rawRIC = d.RIC;
          var splitRIC = d.RIC ? d.RIC.split(".") : [];
          var RIC = splitRIC.length === 2 ? splitRIC[0] : "-";
          var SharesHeldValue = d.SharesHeldValue;
          var PctPortfolio = d.PctPortfolio;
          var SharesHeld = d.SharesHeld;
          var SharesHeldChange = d.SharesHeldChange;
          var SharesHeldChangePct = d.SharesHeldChangePct;
          var PctOfSharesOutstanding = d.PctOfSharesOutstanding;
          var FilingType = d.FilingType;
          var HoldingsDate = isoParse(d.HoldingsDate);
          return {
            SecurityOwnedName: SecurityOwnedName,
            rawRIC: rawRIC,
            RIC: RIC,
            SharesHeldValue: SharesHeldValue,
            PctPortfolio: PctPortfolio,
            SharesHeld: SharesHeld,
            SharesHeldChange: SharesHeldChange,
            SharesHeldChangePct: SharesHeldChangePct,
            PctOfSharesOutstanding: PctOfSharesOutstanding,
            HoldingsDate: HoldingsDate,
            FilingType: FilingType
          };
        } catch (error) {
          if (_this2.dev) {
            console.error("holding error: ", error);
            console.error("error with holdings: ", d);
          }
          return {};
        }
      }).filter(function (f) {
        return f.SharesHeldValue > 0;
      });
    },
    investor: function investor() {
      var rawData = Object.hasOwn(this.$store.state.trkd.investorHoldings, "InvestorDetails") ? this.$store.state.trkd.investorHoldings.InvestorDetails : [];
      return rawData;
    },
    footer: function footer() {
      // FIXME: ability to internationalize text here
      return {
        itemsPerPageOptions: [10, 25, 50, 100, -1]
      };
    },
    loading: function loading() {
      return this.$store.state.trkd.fetchingInvestorHoldings;
    },
    error: function error() {
      return this.$store.state.trkd.investorHoldingsError;
    }
  },
  watch: {
    permId: function permId(newVal) {
      if (newVal && this.$store.state.config.refinitiv) {
        this.$store.dispatch("trkd/fetchInvestorHoldings", {
          id: newVal
        });
      }
    }
  },
  created: function created() {
    var permId = this.$route.query.id;
    if (permId && this.$store.state.config.refinitiv) {
      // do some ticker validation here?
      // TODO: Dispatch action which populates trkd information
      this.$store.dispatch("trkd/fetchInvestorHoldings", {
        id: permId
      });
    }
  }
});