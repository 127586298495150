var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[(_vm.error)?[_c('h3',[_vm._v("\n        "+_vm._s(`${_vm.$Amplify.I18n.get(
            "We do not have holdings for this investor at this time. Please try again later"
          )} ${_vm.$route.query.id}.
        ${_vm.$Amplify.I18n.get("Please report to support@tikr.com")}`)+"\n      ")])]:(_vm.unauth)?[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h3',[_c('nuxt-link',{attrs:{"to":`/account/subs?ref=${_vm.refCode}`}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(`Upgrade Subscription`))])],1),_vm._v("\n            "+_vm._s(_vm.unauthMessage)+"\n          ")],1)])],1)]:(_vm.loading)?[_c('v-layout',{attrs:{"text-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primaryAction","indeterminate":""}})],1)],1)]:(_vm.holdings.length > 0)?[_c('v-layout',{attrs:{"text-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h2',[_vm._v("\n            "+_vm._s(`${
                _vm.investor.Name ? _vm.$Amplify.I18n.get(_vm.investor.Name) : ""
              } ${_vm.$Amplify.I18n.get("Investments")}`)+"\n            ")])])],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-layout',{attrs:{"text-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"headers":_vm.investorColumns,"items":_vm.holdings,"footer-props":_vm.footer,"items-per-page":25,"dense":""},scopedSlots:_vm._u([{key:"item.RIC",fn:function({ item }){return [(item.RIC === '-')?[_vm._v("\n                "+_vm._s(item.RIC)+"\n              ")]:[_c('nuxt-link',{class:{ 'accent--text': _vm.$vuetify.theme.dark },attrs:{"to":`/refid?id=${encodeURIComponent(
                    item.rawRIC
                  )}&ref=${_vm.refCode}`}},[_c('b',[_vm._v(_vm._s(item.RIC))])])]]}},{key:"item.SharesHeldValue",fn:function({ item }){return [_vm._v("\n              "+_vm._s(item.SharesHeldValue === 0
                  ? "-"
                  : _vm.formatCurrency.format(item.SharesHeldValue / 1000000))+"\n            ")]}},{key:"item.PctPortfolio",fn:function({ item }){return [_vm._v("\n              "+_vm._s(item.PctPortfolio === 0
                  ? "-"
                  : _vm.formatPercentTwoDecimals.format(item.PctPortfolio / 100))+"\n            ")]}},{key:"item.SharesHeld",fn:function({ item }){return [_vm._v("\n              "+_vm._s(_vm.displayIntOrDash(item.SharesHeld))+"\n            ")]}},{key:"item.SharesHeldChange",fn:function({ item }){return [_c('span',{class:_vm.displayRedWhenValNegative(item.SharesHeldChange)},[_vm._v("\n                "+_vm._s(_vm.displayIntOrDash(item.SharesHeldChange))+"\n              ")])]}},{key:"item.SharesHeldChangePct",fn:function({ item }){return [_c('span',{class:_vm.displayRedWhenValNegative(item.SharesHeldChangePct)},[_vm._v("\n                "+_vm._s(_vm.displayWholeNumberAsPercentTwoDecimals(
                    item.SharesHeldChangePct
                  ))+"\n              ")])]}},{key:"item.PctOfSharesOutstanding",fn:function({ item }){return [_vm._v("\n              "+_vm._s(item.PctOfSharesOutstanding === 0
                  ? "-"
                  : _vm.formatPercentTwoDecimals.format(
                      item.PctOfSharesOutstanding / 100
                    ))+"\n            ")]}},{key:"item.HoldingsDate",fn:function({ item }){return [_vm._v("\n              "+_vm._s(_vm.utcEpochToShortDate.format(item.HoldingsDate))+"\n            ")]}}])})],1)],1)]:[_vm._v("\n      "+_vm._s(`${_vm.$Amplify.I18n.get("Error: No holdings for")} ${_vm.investor.Name}.
      ${_vm.$Amplify.I18n.get(
        "Please report to support@tikr.com Error: investorId"
      )}: ${_vm.$route.query.id} ${_vm.$Amplify.I18n.get("no holdings")}`)+"\n    ")]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }